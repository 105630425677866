import React, { Component } from "react";
import { Select, Col, Row } from "antd";

import QuerryContext from "../../../contexts/querry";

class AnswerSelect extends Component {
  state = {
    value: "",
  };

  ansId = "";

  static contextType = QuerryContext;

  handleChange = (event) => {
    //this.props.setHidden(event.target.value === "");
    this.context.updateAnswer(event, this.ansId);
    this.setState({ value: event });
  };

  componentDidMount = () => {
    this.props.setHidden(false);
    const answ = this.context.getAnswer(this.ansId);
    if (answ) {
      this.setState({ value: answ });
      this.props.setHidden(answ === "");
    }
  };

  render() {
    this.ansId = this.props.questId + "-text-" + this.props.aid;
    return (
      <div className="text-answer">
        <Row style={{marginTop: "5px"}}>
          <Col span={22} offset={1}>
            <Select
              value={this.state.value?this.state.value:undefined}
              onChange={this.handleChange}
              name={this.ansId}
              placeholder={this.props.answerData.text}
            >
                {this.props.answerData.options.map(v=>(
                    <Select.Option value={v.value}>{v.text}</Select.Option>
                ))}
            </Select>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AnswerSelect;
