import React, { Component } from "react";
import QuestionData from "../../EtGen3.json";
import Question from "./Question";
import { Layout, Form, Button, Row, Col } from "antd";
import { gql } from "apollo-boost";
import { withRouter } from "react-router";
import IdleTimer from "react-idle-timer";

import QuerryContext from "../../contexts/querry";

import ApolloInterface from "../../Apollo";

import { dateDifference } from "../../utils/date";

import Logo from "../../images/2019_pgp-logo_pl.png";

const timeToLogout = 20 * 60 * 1000; //20 min

class Questions extends Component {
  state = {
    fetched: false,
    timeCounter: "00:00",
  };

  lastActivity = new Date().toUTCString();

  componentDidMount = () => {
    this.fetchData();

    const f = () => {
      const d1 = this.lastActivity;
      let d2 = new Date().getTime();
      d2 -= timeToLogout;
      d2 = new Date(d2).toUTCString();

      if (d1 < d2) {
        this.props.questLogout();
        return;
      }

      this.setState({ timeCounter: dateDifference(d1, d2) });
    };

    f();

    this.intervalFunc = setInterval(f, 1000);
  };

  componentWillUnmount = () => {
    clearInterval(this.intervalFunc);
  };

  ansDict = {};

  renderQuestions = () => {
    const questionTab = QuestionData.questions ? QuestionData.questions : [];
    return questionTab.map((elem, index) => {
      return (
        <Question
          key={index}
          number={index + 1}
          questionData={elem}
          hidden={false}
        />
      );
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();

    if (
      event.key === "Enter" ||
      !window.confirm(
        "Wypełniony kwestionariusz zostanie wysłany, nie będzie można już zmienić odpowiedzi."
      )
    )
      return;

    const variables = {};

    const gqlQuery = gql`
      mutation FinishQuestionnaire {
        finishQuestionnaire {
          _id
          status
        }
      }
    `;
    ApolloInterface.runMutation(this.props.getQuestToken(), variables, gqlQuery)
      .then((res) => {
        this.props.questLogout();
        this.props.history.push("/thanks");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchData = () => {
    if (this.props.answers) {
      this.props.answers.forEach((elem) => {
        this.ansDict[elem.question] = elem.text;
      });
      this.setState({ fetched: true });
      return;
    }

    const token = this.props.getQuestToken();
    if (!token) return;
    const gqlQuery = gql`
      {
        answers {
          text
          question
        }
      }
    `;

    ApolloInterface.runQuery(token, gqlQuery)
      .then((res) => {
        res.data.answers.forEach((elem) => {
          this.ansDict[elem.question] = elem.text;
        });
        this.setState({ fetched: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateAnswer = (text, question) => {
    const token = this.props.getQuestToken();
    if (!token) return;
    ApolloInterface.updateAnswer(String(text), String(question), token);
    this.ansDict[question] = text;
  };

  getAnswer = (question) => {
    return this.ansDict[question];
  };

  onAction = () => {
    this.lastActivity = new Date().toUTCString();
  };

  render() {
    const { Header, Content, Footer } = Layout;

    if (!this.state.fetched) return <div></div>;

    return (
      <QuerryContext.Provider
        value={{
          updateAnswer: this.updateAnswer,
          getAnswer: this.getAnswer,
        }}
      >
        <div className="Questions">
          <IdleTimer element={document} onAction={this.onAction} />
          <Header
            style={{
              height: "auto",
              position: "fixed",
              top: "-100",
              zIndex: 998,
              width: "100%",
            }}
          >
            <Row>
              <Col span={2}>
                <img style={{ height: "104px" }} src={Logo} alt="GMP logo" />
              </Col>
              <Col span={20}>
                <Row style={{ height: "40px" }}>
                  <h1 style={{ color: "white" }}>
                    Kwestionariusz uczestnika (GMP)
                  </h1>
                </Row>
                <Row style={{ color: "white" }}>
                  Czas do automatycznego wylogowania: {this.state.timeCounter}
                </Row>
              </Col>
            </Row>
          </Header>
          <Content>
            <Row style={{ backgroundColor: "Gainsboro" }}>
              <Col
                span={20}
                offset={2}
                style={{
                  backgroundColor: "white",
                  textAlign: "left",
                  marginTop: "104px",
                }}
              >
                <Form
                  onSubmit={this.handleSubmit}
                  className="questionnaire-form"
                  style={{ margin: "auto" }}
                  layout="horizontal"
                >
                  {this.renderQuestions()}
                  {!this.props.answers ? (
                    <Form.Item>
                      <Row style={{ textAlign: "center" }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="questionnaire-form-button"
                        >
                          Wyślij
                        </Button>
                      </Row>
                    </Form.Item>
                  ) : null}
                </Form>
              </Col>
            </Row>
          </Content>
          <Footer>
            GMP © PUT 2020
            <br /> <a href="/policy">Polityka prywatności</a>
          </Footer>
        </div>
      </QuerryContext.Provider>
    );
  }
}

export default Form.create({ name: "questionnaire" })(withRouter(Questions));
