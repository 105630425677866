import React, { Component } from "react";
import { Layout } from "antd";

class Thanks extends Component {
  render() {
    const { Header, Content, Footer } = Layout;

    return (
      <div className="Questions">
        <Header>
          <h1 style={{ color: "white" }}>Ankieta uczestnika (GMP)</h1>
        </Header>
        <Content>
          <h2>Dziękujemy za udział w ankiecie!</h2>
        </Content>
        <Footer style={{ position: "fixed", left: 0, bottom: 0, height: "100px", textAlign: "center", width: "100%" }}>
          GMP 2019
          <br /> <a href="/policy">Polityka prywatności</a>
        </Footer>
      </div>
    );
  }
}

export default Thanks;
