const leadingZero = n => {
    if(n < 10){
        return "0" + n
    }

    return "" + n
}

const printDate = str => {
    if(!str){
        return ""
    }

    let d = new Date(str)

    return d.getFullYear() + "-" + leadingZero(d.getMonth() + 1) + "-" + leadingZero(d.getDate()) + " " + leadingZero(d.getHours()) + ":" + leadingZero(d.getMinutes())
}

const dateDifference = (date1, date2) => {
    let d1 = new Date(date1)
    let d2 = new Date(date2)

    let diff = Math.abs(d2 - d1)

    let res = ""

    diff = Math.floor(diff / 1000)

    let days = Math.floor(diff / (24 * 60 * 60))

    if(days){
        res += days + "d "
        diff = diff % (24 * 60 * 60)
    }

    let hours = Math.floor(diff / (60 * 60))

    if(hours){
    diff = diff % (60 * 60)
    res += leadingZero(hours) + ":"
    }

    let minutes = Math.floor(diff / 60)
    diff = diff % 60
    res += leadingZero(minutes) + ":" + leadingZero(diff)

    return res
}

export { printDate, dateDifference }