import React from "react";
import { Col, Row, Card } from "antd";

import Question from "../Question.js";

const drawPerson = (id, name, questions) => {
  const renderedQuestions = questions.map((question, index) => {
    return (
      <Question
        questionData={question}
        questIdSuffix={id}
        key={name + "b" + index}
      />
    );
  });
  return <Card title={name}>{renderedQuestions}</Card>;
};

const drawLines = () => {
  return (
    <svg
      style={{
        width: "100%",
        height: "100%"
      }}
    >
      <line
        x1="50%"
        y1="0%"
        x2="50%"
        y2="50%"
        style={{ stroke: "rgb(0,0,0)", strokeWidth: 1 }}
      />
      <line
        x1="1%"
        y1="50%"
        x2="99%"
        y2="50%"
        style={{ stroke: "rgb(0,0,0)", strokeWidth: 1 }}
      />
      <line
        x1="1%"
        y1="50%"
        x2="1%"
        y2="100%"
        style={{ stroke: "rgb(0,0,0)", strokeWidth: 1 }}
      />
      <line
        x1="99%"
        y1="50%"
        x2="99%"
        y2="100%"
        style={{ stroke: "rgb(0,0,0)", strokeWidth: 1 }}
      />
    </svg>
  );
};

export default (props) => {
  const ansId = props.questId + "-family-tree-" + props.aid;
  return (
    <div className="answer-family-tree">
      <Col span={24}>
        <Row justify="center">
          <Col span={4} offset={10}>
            <Card title={<div>Osoba<br />ankietowana</div>}></Card>
          </Col>
        </Row>
        <Row>
          <Col span={12} offset={6}>
            {drawLines()}
          </Col>
        </Row>
        <Row justify="center">
          <Col span={4} offset={4}>
            {drawPerson(ansId + "mother", "matka", props.answerData.questions)}
          </Col>
          <Col span={4} offset={8}>
            {drawPerson(ansId + "father", "ojciec", props.answerData.questions)}
          </Col>
        </Row>
        <Row>
          <Col span={6} offset={3}>
            {drawLines()}
          </Col>
          <Col span={6} offset={6}>
            {drawLines()}
          </Col>
        </Row>
        <Row justify="center">
        <Col span={4} offset={1}>
            {drawPerson(ansId + "mgrandmother", "babcia", props.answerData.questions)}
          </Col>
          <Col span={4} offset={2}>
            {drawPerson(ansId + "mgrandfather", "dziadek", props.answerData.questions)}
          </Col>
          <Col span={4} offset={2}>
            {drawPerson(ansId + "fgrandmotherr", "babcia", props.answerData.questions)}
          </Col>
          <Col span={4} offset={2}>
            {drawPerson(ansId + "fgrandfather", "dziadek", props.answerData.questions)}
          </Col>
        </Row>
      </Col>
    </div>
  );
};
