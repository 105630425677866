import React, { Component } from 'react';
import { Col, Row, Checkbox } from 'antd';

import QuerryContext from '../../../contexts/querry';

class AnswerCheckBox extends Component {
    state = {
        checked: false
    }

    static contextType = QuerryContext;
    
    ansId = "";

    handleChange = (event) => {
        this.props.setHidden(!event.target.checked);
        this.setState({checked: event.target.checked});
        this.context.updateAnswer(String(event.target.checked), this.ansId);
    }

    componentDidMount = () => {
        this.props.setHidden(true);
        const answ = this.context.getAnswer(this.ansId);
        if(answ){
            if(answ === "true"){
                this.setState({checked: true});
                this.props.setHidden(false);
            }
            else{
                this.setState({checked: false});
            }
        }
    }

    render() {
        this.ansId = this.props.questId + "-checkbox-" + this.props.aid;
        return (
                <div className="checkbox-answer">
                    <Row type="flex" justify="center">
                        <Col span={5}>
                            <Row type="flex" justify="start">
                                <Checkbox checked={this.state.checked} name={this.ansId} onChange={this.handleChange}>{this.props.answerData.text}</Checkbox>
                            </Row>
                        </Col>
                    </Row>
                </div>
        );
    }
}

export default AnswerCheckBox;