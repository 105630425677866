import React, { Component } from "react";
import { gql } from "apollo-boost";
import { Table, Button, Input, Row, Col, Upload } from "antd";
import { RedoOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";

import CredentialsContext from "../contexts/credentials";

import "../../node_modules/font-awesome/less/font-awesome.less";

import "../styles/Questionnaires.css";
import ApolloInterface from "../Apollo";

import { questionnairesColumns } from "../utils/dataColumns";

import { containsPhrase } from "../utils/string";

class Questionnaires extends Component {
  state = {
    data: [],
    selectedRowKeys: [],
    searchPhrase: "",
    CSVLoading: false,
    PDFLoading: false,
  };

  static contextType = CredentialsContext;

  back = () => {
    this.props.history.push("/");
  };

  exportToPdf = (hashlink) => {
    const token = this.context.token;
    const gqlQuery = gql`
      {
        retrieveAnswers(hashlink: \"${hashlink}\") {
          text
          question
        }
      }
    `;
    return ApolloInterface.runQuery(token, gqlQuery)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchData = () => {
    const token = this.context.token;
    const gqlQuery = gql`
      {
        questionnaires {
          _id
          hashlink
          sample_id
          email
          admin_email
          status
          created
          last_accessed
          last_printed
        }
      }
    `;

    ApolloInterface.runQuery(token, gqlQuery)
      .then((res) => {
        return this.setState({
          data: res.data.questionnaires.map((quest, index) => {
            quest.key = index;
            quest.pin = (
              <div>
                <button
                  type="button"
                  className="regeneratePinButton invisibleButton"
                  onClick={() => {
                    this.generateNewPin(
                      quest.hashlink,
                      quest.sample_id
                    );
                  }}
                >
                  <RedoOutlined />
                </button>
              </div>
            );
            quest.export_to_pdf = (
              <div>
                <button
                  type="button"
                  className="exportToPdfButton"
                  onClick={() => {
                    this.exportToPdf(quest.hashlink);
                  }}
                >
                  <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                </button>
              </div>
            );
            return quest;
          }),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount = () => {
    this.fetchData();
  };

  generateNewPin = (hashlink, sample_id) => {
    const variables = { hashlink: hashlink };
    const gqlQuery = gql`
      mutation GenerateNewPin($hashlink: String!) {
        generateNewPin(hashlink: $hashlink)
      }
    `;
    ApolloInterface.runMutation(this.context.token, variables, gqlQuery)
      .then((res) => {
        alert(
          "Pin generated again for " + sample_id + ": " + res.data.generateNewPin
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onSelectChange = (selectedRowKeys) => {
    //console.log("selectedRowKeys changed: ", selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  deleteSelected = (e) => {
    if (
      !window.confirm(
        `Are you sure you want to delete ${this.state.selectedRowKeys.length} questionnaire(s)`
      )
    ) {
      return;
    }
    const ids = this.state.selectedRowKeys.map((v) => this.state.data[v]._id);

    const variables = { ids };
    const gqlQuery = gql`
      mutation DeleteQuestionnaires($ids: [String!]!) {
        deleteQuestionnaires(ids: $ids)
      }
    `;
    ApolloInterface.runMutation(this.context.token, variables, gqlQuery)
      .then(() => {
        this.clearSelection();
        this.fetchData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onSearchPhraseChanged = (e) => {
    this.setState({ searchPhrase: e.target.value });
  };

  filterData = (data) => {
    const p = this.state.searchPhrase;

    if (p === "") {
      return data;
    }

    const fields = [
      "created",
      "sample_id",
      "email",
      "admin_email",
      "last_accessed",
      "status",
    ];

    return data.filter((v) => {
      for (let i = 0; i < fields.length; ++i) {
        const f = fields[i];
        if (containsPhrase(String(v[f]), p)) return true;
      }

      return false;
    });
  };

  clearSelection = (e) => {
    this.setState({ selectedRowKeys: [] });
  };

  parseCSV = (str) => {
    let lines = str.split(/\r?\n/);

    for (let i = 0; i < lines.length; ++i) {
      lines[i] = lines[i].split(",");
    }

    let dict = {};

    for (let i = 0; i < lines[0].length; ++i) {
      dict[lines[0][i]] = i;
    }

    lines.shift();

    lines = lines.filter((v) => {
      return v.length > 2;
    });

    const arr = lines.map((v) => {
      const sample_id = v[dict["sample_id"]];
      const email = v[dict["email"]];
      const admin_email = v[dict["admin_email"]];

      return {
        sample_id,
        email,
        admin_email,
      };
    });

    const variables = { questionnaireInput: arr };
    const gqlQuery = gql`
      mutation CreateQuestionnaires(
        $questionnaireInput: [QuestionnaireInput]!
      ) {
        createQuestionnaires(questionnaireInput: $questionnaireInput)
      }
    `;
    ApolloInterface.runMutation(this.context.token, variables, gqlQuery)
      .then(() => {
        this.clearSelection();
        this.fetchData();
        this.setState({ CSVLoading: false });
      })
      .catch((err) => {
        console.log(err);
      });

    //console.log(arr)
  };

  readCSV = (file) => {
    this.setState({ CSVLoading: true });
    const reader = new FileReader();

    reader.onload = (e) => {
      this.parseCSV(reader.result);
    };
    reader.readAsText(file);

    // Prevent upload
    return false;
  };

  printSelected = async () => {
    this.setState({ PDFLoading: true });
    const hashlinks = this.state.selectedRowKeys.map(
      (v) => this.state.data[v].hashlink
    );

    for (let i = 0; i < hashlinks.length; ++i) {
      await this.exportToPdf(hashlinks[i]);
    }

    this.fetchData();

    this.setState({ PDFLoading: false });
  };

  render() {
    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <div className="Questionnaires">
        <Col span={24}>
          <Row gutter={[0, 50]}>
            <Col span={17}>
              <Row style={{ textAlign: "left" }}>
                <NavLink to="/admin/new-questionnaire">
                  <Button
                    title="New questionnaire"
                    style={{ marginRight: "10px" }}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </Button>
                </NavLink>
                <Button
                  style={{ marginRight: "10px" }}
                  onClick={this.clearSelection}
                  title="Clear selection"
                >
                  <i className="fa fa-ban" aria-hidden="true"></i>
                </Button>
                <Button
                  style={{ marginRight: "10px" }}
                  onClick={this.deleteSelected}
                  title="Delete selected"
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </Button>
                <Button
                  style={{ marginRight: "10px" }}
                  onClick={this.printSelected}
                  loading={this.state.PDFLoading}
                  title="Print selected"
                >
                  <i className="fa fa-print" aria-hidden="true"></i>
                </Button>
                <Upload
                  showUploadList={false}
                  accept=".csv"
                  beforeUpload={(file) => this.readCSV(file)}
                >
                  <Button
                    onClick={this.importFromCSV}
                    loading={this.state.CSVLoading}
                    title="Upload CSV"
                  >
                    <i className="fa fa-upload" aria-hidden="true"></i>
                  </Button>
                </Upload>
              </Row>
            </Col>
            <Col span={7}>
              <Input
                placeholder="Search"
                value={this.state.searchPhrase}
                onChange={this.onSearchPhraseChanged}
              />
            </Col>
          </Row>
          <Row>
            <Table
              scroll={{ x: true }}
              classname="questionnairesTable"
              rowSelection={rowSelection}
              dataSource={this.filterData(this.state.data)}
              columns={questionnairesColumns}
            />
          </Row>
        </Col>
      </div>
    );
  }
}

export default Questionnaires;
