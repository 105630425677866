import React, { Component } from "react";
import { Form, Icon, Input, Button, Row, Col } from "antd";
import ApolloInterface from "../../Apollo";

import Logo from "../../images/2019_pgp-logo_pl.png";

class PinLogin extends Component {
  state = {
    wrongPin: false,
  };

  mainInput = React.createRef();

  componentDidMount = () => {
    this.mainInput.focus();
  };

  handleSubmit = (event) => {
    event.preventDefault();

    let wrong = false;

    this.props.form.validateFields((err, values) => {
      if (!err) {
        //console.log('Received values of form: ', values);
      } else {
        wrong = true;
      }
    });

    if (wrong) {
      return;
    }

    const pin = this.props.form.getFieldValue("pin");
    const hashlink = this.props.urlHash;

    ApolloInterface.questLogin(hashlink, pin)
      .then((res) => {
        this.setState({ wrongPin: false });
        this.props.questLogin(
          hashlink,
          res.data.questionnaireLogin.token,
          res.data.questionnaireLogin.tokenExpiration
        );
      })
      .catch((err) => {
        this.setState({ wrongPin: true });
      });
  };

  handleChange = (event) => {
    this.setState({ wrongPin: false });
  };

  generateWrongPinMessage = () => {
    if (this.state.wrongPin) {
      return (
        <p style={{ color: "red" }}>
          Wrong pin or questionnaire does not exist!
        </p>
      );
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="PinLogin">
        <Row>
          <Col span={6} offset={9} style={{ marginTop: "60px" }}>
            <Row>
              <Col span={20} offset={2}>
                <img src={Logo} alt="GMP logo" style={{ width: "60%" }} />
                <Form
                  onSubmit={this.handleSubmit}
                  className="quest-login-form"
                  style={{ width: "60%", margin: "auto" }}
                >
                  <Form.Item>
                    {getFieldDecorator("pin", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your personal pin!",
                        },
                      ],
                    })(
                      <Input
                        ref={(input) => {
                          this.mainInput = input;
                        }}
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        type="password"
                        placeholder="Pin code"
                        onChange={this.handleChange}
                      />
                    )}
                    {this.generateWrongPinMessage()}
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="quest-login-form-button"
                    >
                      Log in
                    </Button>
                  </Form.Item>
                  <footer style={{ position: "fixed", left: 0, bottom: 0, height: "50px", textAlign: "center", width: "100%" }}>
                    <a href="/policy">
                      Polityka prywatności
                    </a>
                  </footer>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Form.create({ name: "quest-login" })(PinLogin);
