import React, { Component } from 'react';
import { Slider, InputNumber, Row, Col } from 'antd';
import QuerryContext from '../../../contexts/querry';

class AnswerAntSlider extends Component {
    static contextType = QuerryContext;
    
    ansId = "";

    state = {
        inputValue: this.props.answerData.defaultValue,
    };
    onChange = value => {
        this.context.updateAnswer(value, this.ansId);
        this.setState({
            inputValue: value,
        });
    };

    componentDidMount = () => {
        this.props.setHidden(true);
        const answ = this.context.getAnswer(this.ansId);
        if(answ){
            this.setState({inputValue: Number(answ)});
        }
    }

    render() {
        this.ansId = this.props.questId + "-slider-" + this.props.aid;
        const { inputValue } = this.state;
        return (
            <div className="ant-slider-answer" style={{marginTop: "20px", marginBottom: "20px"}}>
                <Row type="flex" justify="center">
                    {this.props.answerData.text}
                </Row>
                <Row type="flex" justify="center">
                    <Col span={6}>
                        <Slider
                            min={this.props.answerData.min}
                            max={this.props.answerData.max}
                            step={this.props.answerData.step}
                            onChange={this.onChange}
                            value={typeof inputValue === 'number' ? inputValue : 0}
                        />
                    </Col>
                    <Col span={4}>
                        <InputNumber
                        min={this.props.answerData.min}
                        max={this.props.answerData.max}
                        step={this.props.answerData.step}
                            style={{ marginLeft: 16 }}
                            value={inputValue}
                            onChange={this.onChange}
                        />
                    </Col>
                </Row >
            </div>
        );
    }
}

export default AnswerAntSlider;